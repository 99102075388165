<template>
  <div>
    <b-row class="mb-1">
      <b-col cols="3">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            TOTAL DE LLAMADOS
          </b-card-header>
          <b-row>
            <b-col
              v-for="(item, index) in allCalled"
              :key="index"
              cols="12"
            >
              <h5 class="ml-2 mb-0">
                {{ item.type }}: {{ item.total }}
              </h5>
              <div
                class="d-flex justify-content-between"
                style="padding: 0.3rem !important;"
              >
                <div class="text-center">
                  <div>Atendidos</div>
                  <b-badge
                    :variant="item.variant1"
                    style="font-size: 17px;"
                  >
                    {{ item.catered }}
                  </b-badge>
                </div>
                <div class="text-center">
                  <div>No Atendidos</div>
                  <b-badge
                    :variant="item.variant2"
                    style="font-size: 17px;"
                  >
                    {{ item.notattended }}
                  </b-badge>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="3">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            TOTAL DE DIETAS
          </b-card-header>
          <b-row>
            <b-col
              v-for="(item, index) in diets"
              :key="index"
              cols="12"
            >
              <h5 class="ml-2 mb-0">
                {{ item.type }}: {{ item.total }}
              </h5>
              <div class="padding__">
                <b-progress
                  :key="item.variant"
                  :max="item.total"
                  animated
                  :variant="item.variant"
                  class="'progress-bar-' + item.variant"
                >
                  <b-progress-bar :value="item.delivered">
                    <strong>{{ item.delivered }}</strong>
                  </b-progress-bar>
                </b-progress>
                <span class=""><strong>{{ item.delivered }} / {{ item.total }}</strong></span>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="3">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            TOTAL DE MEDICAMENTOS
          </b-card-header>
          <b-row>
            <b-col
              v-for="(item, index) in medicines"
              :key="index"
              cols="12"
            >
              <h5 class="ml-2 mb-0">
                {{ item.type }}
              </h5>
              <div class="padding__">
                <b-badge
                  :variant="item.variant"
                  style="font-size: 27.8px;"
                >
                  {{ item.total }}
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
      <b-col cols="3">
        <b-card
          no-body
          class="card__"
        >
          <b-card-header class="p-1">
            TOTAL TRANSLADOS
          </b-card-header>
          <b-row>
            <b-col
              cols="12"
            >
              <div class="padding__">
                <b-badge
                  variant="success"
                  style="font-size: 40px;"
                >
                  10
                </b-badge>
              </div>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>

    <b-row v-if="patient">
      <b-card class="card__">
        <b-row>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              Paciente
            </div>
            <div class="subtitle">
              {{ patient.first_name }} {{ patient.last_name }}
            </div>
          </b-col>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              Tipo de identificaciòn
            </div>
            <div class="subtitle">
              {{ patient.document_type.long_name }}
            </div>
          </b-col>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              Identificaciòn
            </div>
            <div class="subtitle">
              {{ patient.document_number }}
            </div>
          </b-col>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              Fecha de ingreso
            </div>
            <div class="subtitle">
              27/10/2021
            </div>
          </b-col>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              Fecha de salida
            </div>
            <div class="subtitle">
              28/10/2021
            </div>
          </b-col>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              Días estancia
            </div>
            <div class="subtitle">
              10
            </div>
          </b-col>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              Reingreso
            </div>
            <div class="subtitle">
              2
            </div>
          </b-col>
          <b-col
            cols="3"
            class="mb-1"
          >
            <div class="title">
              EPS
            </div>
            <div class="subtitle">
              {{ patient.hpc.long_name }}
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            md="2"
            sm="4"
            class="my-1"
          >
            <b-form-group
              class="mb-0"
            >
              <label class="d-inline-block text-sm-left mr-50">Por página</label>
              <b-form-select
                id="perPageSelect"
                v-model="perPage"
                size="sm"
                :options="pageOptions"
                class="w-50"
              />
            </b-form-group>
          </b-col>
          <b-col
            md="6"
            class="my-1"
          >
            <b-form-group
              label="Filter"
              label-cols-sm="3"
              label-align-sm="right"
              label-size="sm"
              label-for="filterInput"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filterInput"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                />
                <b-input-group-append>
                  <b-button
                    :disabled="!filter"
                    @click="filter = ''"
                  >
                    Limpiar
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>

          <b-col cols="12">
            <b-table
              responsive
              class="text-center"
              small
              :per-page="perPage"
              :current-page="currentPage"
              :items="items"
              :fields="fields"
              :filter="filter"
              :filter-included-fields="filterOn"
              @filtered="onFiltered"
            />
          </b-col>

          <b-col
            cols="12"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              align="center"
              size="sm"
              class="my-0"
            />
          </b-col>
        </b-row>
      </b-card>
    </b-row>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BBadge,
  VBModal,
  BCardHeader,
  BProgress,
  BProgressBar,
  BTable,
  BFormGroup,
  BFormSelect,
  BPagination,
  BInputGroup,
  BFormInput,
  BInputGroupAppend,
  BButton,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
	inject: ['patientsRepository'],
  components: {
    BCard,
    BRow,
    BCol,
    BTable,
    BFormGroup,
    BFormSelect,
    BPagination,
    BInputGroup,
    BFormInput,
    BInputGroupAppend,
    BButton,
    BCardHeader,
    BBadge,
    BProgress,
    BProgressBar,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  data() {
    return {
      perPage: 5,
      pageOptions: [3, 5, 10],
      totalRows: 1,
      currentPage: 1,
			patient: null,
      filter: null,
      filterOn: [],
      fields: [
        {
          key: 'service', label: 'Servicio',
        },
        {
          key: 'location', label: 'Cama', sort: true,
        },
        {
          key: 'type', label: 'Tipo', sort: true,
        },
        {
          key: 'note', label: 'Nota', sort: true,
        },
        {
          key: 'created_at', label: 'Fecha', sort: true,
        },
      ],
      items: [],
      allCalled: [
        {
          type: 'Alerta de incendios',
          total: 40,
          catered: 20,
          notattended: 20,
          variant1: 'primary',
          variant2: 'danger',
        },
        {
          type: 'Código Azul',
          total: 30,
          catered: 10,
          notattended: 20,
          variant1: 'primary',
          variant2: 'danger',
        },
        {
          type: 'Código Rojo',
          total: 10,
          catered: 0,
          notattended: 10,
          variant1: 'primary',
          variant2: 'danger',
        },
      ],
      diets: [
        {
          type: 'Desayunos',
          total: 60,
          delivered: 20,
          variant: 'success',
        },
        {
          type: 'Almuerzos',
          total: 80,
          delivered: 56,
          variant: 'primary',
        },
        {
          type: 'Cenas',
          total: 100,
          delivered: 69,
          variant: 'danger',
        },
      ],
      medicines: [
        {
          type: 'Programadas',
          total: 60,
          variant: 'success',
        },
        {
          type: 'Ejecutadas',
          total: 40,
          variant: 'primary',
        },
        {
          type: 'Devoluciones',
          total: 10,
          variant: 'danger',
        },
      ],
    }
  },
  mounted() {
    // Set the initial number of items
    this.totalRows = this.items.length
		this.getPatients()
		this.getHistory()
  },
  methods: {
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length
      this.currentPage = 1
    },

		async getPatients() {
			try {
				const patient_id = this.$route.params.id
				const response = await this.patientsRepository.find(patient_id)
				this.patient = response.data.data
				console.log(response)
			} catch (error) {
				console.log(error)
			}
		},
		async getHistory() {
			const me = this
			try {
				const patient_id = this.$route.params.id
				const response = await this.patientsRepository.history(patient_id)

				const { data } = response

				console.log(data)
				this.items = data.map(item => ({
						service: item.data.service_name,
						location: item.data.location_name,
						type: me.getTypeName(item),
						note: item.data.note,
						// item.created_at =  2024-04-13T04:31:33.000000Z
						created_at: new Date(item.created_at).toLocaleString('es-ES'),
					}))
			} catch (error) {
				console.log(error)
			}
		},
		getTypeName(data) {
			console.log(data.historyable_type)
			if (data.historyable_type === 'App\\Models\\Call') {
				return 'llamado'
			}
				return 'Desconocido'
		},
  },
}
</script>

<style lang="scss" scoped>
  .card__ {
    box-shadow: 0 5px 11px 0 rgba(145, 144, 144, 0.3), 0 4px 15px 0 rgba(133, 132, 132, 0.15);
  }

  .title {
    font-size: 16px;
    color: #636363;
    font-weight: 800;
    margin-bottom: 5px;
  }

  .subtitle {
    font-size: 14px;
    color: #636363;
  }
</style>
